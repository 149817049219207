<template>
  <b-container
    fluid
  >
    <div class="d-flex justify-content-end mb-0 pb-0">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-container fluid>
      <b-row>

        <b-col
          cols="12"
          class="px-1"
        >
          <b-form-group
            label="Search"
            label-for="filter_document_type"
            label-class="font-weight-bolder"
          >
            <b-input
              id="filter_document_type"
              v-model="tableDocumentType.filter.search"
              type="text"
              size="sm"
              placeholder="search here"
              debounce="1000"
              autocomplete="off"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          class="px-1 mb-2"
        >
          <b-button
            size="sm"
            type="button"
            variant="success"
            @click="onCreateDocumentType"
          >
            Add Document Type
          </b-button>
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col
          cols="12"
          class="px-1"
        >
          <b-table
            ref="tableDocumentType"
            hover
            small
            striped
            bordered
            responsive
            show-empty
            :stacked="isMobile()"
            :per-page="tableDocumentType.perPage"
            :current-page="tableDocumentType.currentPage"
            :items="tableDocumentTypeProvider"
            :fields="tableDocumentType.fields"
            :sort-by.sync="tableDocumentType.sortBy"
            :sort-desc.sync="tableDocumentType.sortDesc"
            :sort-direction="tableDocumentType.sortDirection"
            :filter="tableDocumentType.filter"
            :filter-included-fields="tableDocumentType.filterOn"
            :busy="tableDocumentType.busy"
          >
            <template #cell(index)="row">
              {{ tableDocumentType.currentPage * tableDocumentType.perPage - tableDocumentType.perPage + (row.index + 1) }}
            </template>

            <template #cell(active)="row">
              {{ Number(row.value) === 1 ? 'Yes' : 'No' }}
            </template>

            <template #cell()="row">
              <div class="text-md-nowrap">
                {{ row.value }}
              </div>
            </template>

            <template #cell(action)="row">
              <div class="text-md-nowrap d-flex flex-column flex-md-row justify-content-center">
                <b-button
                  size="sm"
                  variant="success"
                  @click="onEditDocumentType(row.item)"
                >
                  Edit
                </b-button>
              </div>
            </template>

            <template #table-busy>
              <div class="text-center py-5">
                <b-icon
                  icon="stopwatch"
                  font-scale="5"
                  animation="cylon"
                />
                <p class="h3 py-2">
                  <strong>Loading . . .</strong>
                </p>
              </div>
            </template>

          </b-table>
        </b-col>

        <b-col
          cols="12"
          md="6"
          class="mt-1 mb-2 px-1"
        >
          <b-select
            v-model="tableDocumentType.perPage"
            :options="tableDocumentType.pageOptions"
            size="sm"
            class="w-100 w-md-25"
          />
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="d-flex justify-content-center justify-content-md-end mt-1 px-1"
        >
          <b-pagination
            v-model="tableDocumentType.currentPage"
            pills
            last-number
            first-number
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
            :total-rows="tableDocumentType.totalRows"
            :per-page="tableDocumentType.perPage"
          />
        </b-col>
      </b-row>
    </b-container>

    <b-modal
      id="modal-document-type"
      scrollable
      no-close-on-esc
      no-enforce-focus
      hide-header-close
      no-close-on-backdrop
      :title="ModalDocumentTypeTitle"
    >
      <ValidationObserver
        ref="formDocumentType"
      >
        <form
          role="form"
          novalidate
          @submit.prevent
        >
          <b-row>
            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="document type"
                vid="document_type_name"
                rules="required|max:100"
              >
                <b-form-group
                  label="Document Type"
                  label-for="document_type_name"
                  label-class="font-weight-bolder"
                  class="mb-2"
                >
                  <b-input
                    id="document_type_name"
                    v-model="documentType.document_type_name"
                    type="text"
                    autocomplete="off"
                    maxlength="100"
                    placeholder="enter document type here"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col
              v-if="state.editing"
              cols="12"
            >
              <b-form-group>
                <b-form-checkbox
                  id="is_active"
                  v-model="documentType.active"
                  switch
                  value="1"
                  name="is_active"
                  unchecked-value="0"
                  :disabled="state.busy"
                >
                  Is Active?
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>

        </form>
      </ValidationObserver>

      <template #modal-footer="{cancel}">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click="onValidateDocumentType"
        >
          {{ state.editing ? 'Update Record' : 'Save Record' }}
        </b-button>
        <b-button
          variant="outline-dark"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>

    </b-modal>
  </b-container>
</template>

<script>
import QUERY from 'lodash'
import { core } from '@/config/pluginInit'
import { TADocumentTypeService } from '@/services'
import formatter from '@/mixins/formatter'
import misc from '@/mixins/misc'

export default {
  name: 'TaMaintenancesDocumentTypes',

  middleware: ['auth', 'ta'],

  mixins: [misc, formatter],

  metaInfo: () => ({
    title: 'Document Types'
  }),

  data () {
    return {
      state: {
        busy: false,
        editing: false
      },
      documentType: {
        id: null,
        document_type_name: null,
        active: 1
      },
      tableDocumentType: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          search: null
        },
        filterOn: [],
        busy: false,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 5, key: 'action', class: 'text-center' },
          { mobile: 1, key: 'created_at', formatter: this.dateTimeShortFormatter, sortable: true },
          { mobile: 2, key: 'document_type_name', label: 'document type' },
          { mobile: 3, key: 'active', class: 'text-center' },
          { mobile: 4, key: 'updated_at', formatter: this.dateTimeShortFormatter, sortable: true }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  computed: {
    ModalDocumentTypeTitle () {
      return this.state.editing ? 'Edit Document Type' : 'Add Document Type'
    }
  },

  mounted () {
    core.index()
  },

  methods: {
    async tableDocumentTypeProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tableDocumentType.busy = true
      return await TADocumentTypeService.get(
        this.objectToUrl({
          page: currentPage,
          per_page: perPage,
          sort: sortBy,
          sort_desc: sortDesc,
          filter_text: filter.search
        })
      ).then(({ data }) => {
        this.tableDocumentType.totalRows = data.total_rows
        return data.items
      }).catch(() => []).finally(() => {
        this.tableDocumentType.busy = false
      })
    },

    onCreateDocumentType () {
      this.state.editing = false
      this.documentType.id = null
      this.documentType.document_type_name = null
      this.documentType.active = 1
      this.$bvModal.show('modal-document-type')
    },

    onEditDocumentType (documentType) {
      this.state.editing = true
      this.documentType.id = documentType.id
      this.documentType.document_type_name = documentType.document_type_name
      this.documentType.active = documentType.active
      this.$bvModal.show('modal-document-type')
    },

    async onValidateDocumentType (event) {
      event.preventDefault()
      await this.$refs.formDocumentType.validate().then(
        async allFieldsAreValid => {
          if (allFieldsAreValid) {
            this.swalConfirm({
              html: () => {
                if (this.state.editing) {
                  return '<h6>Save your Changes on this Document Type?</h6>'
                }
                return '<h6>Create this Document Type?</h6>'
              },
              preConfirm: () => {
                this.state.busy = true
                if (this.state.editing) {
                  return this.onPutDocumentType()
                }
                return this.onPostDocumentType()
              }
            })
          } else {
            this.swalInvalid()
          }
        }
      )
    },

    async onPostDocumentType () {
      return new Promise(resolve => {
        TADocumentTypeService.post(this.documentType).then(
          ({ data: { message } }) => {
            this.$refs.tableDocumentType.refresh()
            this.swalSuccess(message).then(() => {
              this.$bvModal.hide('modal-document-type')
            })
            resolve(message)
          }
        ).catch(({ message }) => {
          this.$refs.formDocumentType.setErrors(message)
          resolve(message)
        }).finally(() => {
          this.state.busy = false
        })
      })
    },

    async onPutDocumentType () {
      return new Promise(resolve => {
        TADocumentTypeService.put(this.documentType).then(
          ({ data }) => {
            const tableDocumentTypeRow = QUERY.find(this.$refs.tableDocumentType.localItems, { id: data.document_type.id })
            if (tableDocumentTypeRow) {
              tableDocumentTypeRow.document_type_name = data.document_type.document_type_name
              tableDocumentTypeRow.active = data.document_type.active
              tableDocumentTypeRow.updated_at = data.document_type.updated_at
            }
            this.swalSuccess(data.message).then(() => {
              this.$bvModal.hide('modal-document-type')
            })
            resolve(data)
          }
        ).catch(({ message }) => {
          this.$refs.formDocumentType.setErrors(message)
          resolve(message)
        }).finally(() => {
          this.state.busy = false
        })
      })
    }
  }
}
</script>
